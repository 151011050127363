import i18n from 'src/utils/i18n';

export class VariableHelper {
  static getYesNo(value: boolean) {
    return value === true ? i18n.t('Yes') : i18n.t('No');
  }

  static isDefined<T>(value: T | undefined): boolean {
    return value !== undefined;
  }
}
