import { combineReducers, configureStore } from '@reduxjs/toolkit';
import CustomizerReducer from './customizer/CustomizerSlice';

import {
  useDispatch as useAppDispatch,
  useSelector as useAppSelector,
  TypedUseSelectorHook,
} from 'react-redux';
import FilterSlice from './FilterSlice';
import SortSlice from './SortSlice';

export const store = configureStore({
  reducer: {
    customizer: CustomizerReducer,
    filter: FilterSlice,
    sort: SortSlice,
  },
});

const rootReducer = combineReducers({
  customizer: CustomizerReducer,
  filter: FilterSlice,
  sort: SortSlice,
});

export type AppState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
export const { dispatch } = store;
export const useDispatch = () => useAppDispatch<AppDispatch>();
export const useSelector: TypedUseSelectorHook<AppState> = useAppSelector;

export default store;
