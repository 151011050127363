export enum DocumentCategoryEnum {
  PARTNER_OBJECT_IMAGE = 'partner.object.image',
}

export type IDocuments = {
  id?: string;
  category?: string;
  originName?: string;
  name?: string;
  mimeType?: string;
  handlerId?: string;
  handlerType?: string;
  fileSize?: number;
  scheduledDelete?: Date;
  partnerIdentifier?: string;
};

export type IDocumentsWithTitle = IDocuments & {
  title: ILanguageRecordObject;
};

export type ILanguageRecordObject = {
  en: string;
  de: string;
};
