import PageContainer from 'src/components/container/PageContainer';
import Header from 'src/layouts/full/shared/header/Header';

import { useTranslation } from 'react-i18next';
import ObjectSection from './components/object-section/object-section';
import { useSelector } from 'src/store/Store';
import ObjectDetails from './components/object-details/object-details';
import ObjectMessage from './components/invite-applicants/message';
import ShareLink from './components/invite-applicants/share-link';

const InviteApplicants = () => {
  const { t } = useTranslation();
  const { object } = useSelector((state) => state.filter);

  const link = `${process.env.REACT_APP_RENTCARD_FRONT_URL}/?object=${object.id}`;

  return (
    <PageContainer title={String(t('Applicant Overview'))} description="Overview table">
      <Header title={String(t('Invite applicants'))} />
      <ObjectSection includeAll={false} data={object} />
      <ObjectDetails data={object} />
      <ObjectMessage link={link} />
      <br />
      <ShareLink link={link} />
    </PageContainer>
  );
};

export default InviteApplicants;
